@import "../../scss/variables";
@import "../../scss/mixins";

.jumbotron {
  &--hero {
    &__card {
      display: flex;
      align-items: center;
      img,
      p {
        margin: 0;
      }
      p {
        color: #fff;
        font-size: 1.3rem;
      }
      img {
        height: 50px;
      }
    }
    &-steel {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.73),
          rgba(0, 0, 0, 0.33)
        ),
        url("../../assets/steel-1.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 800px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
      margin-bottom: 0 !important;
      @include breakpoint($md) {
        background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.73),
            rgba(0, 0, 0, 0.33)
          ),
          url("../../assets/steel-1.jpg");
        height: 105vh;
        margin-top: -8rem !important;
      }
      .video {
        @include breakpoint($xs) {
          width: 0;
          height: 0;
        }
        height: 100%;
        width: 200%;
        object-fit: cover;
      }
      .container {
        position: absolute;
        width: 100%;
        top: 12%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include breakpoint($ipad-port) {
          top: 0;
        }
      }
      h1 {
        font-size: 4.5rem;
        text-align: center;
        color: #fff;
        @include breakpoint($md) {
          font-size: 6rem;
          margin-bottom: 2rem;
        }
      }
    }
  }
}
.carousel {
  height: 1200px;
  @include breakpoint($md) {
    height: 850px;
  }
  @include breakpoint($lg) {
    height: 100vh;
  }
  @include breakpoint($xl) {
    height: 900px !important;
  }
  &-inner,
  &-item {
    height: 100%;
  }
  &--steel {
    &-container {
      .carousel {
        @include breakpoint($xs) {
          height: 1000px !important;
        }
        // @include breakpoint($xl) {
        //   height: 900px ;
        // }
      }
    }
    &__applications {
      &-container {
        height: 600px;
        position: relative;
        @include breakpoint($md) {
          height: 100vh;
        }
        @include breakpoint($xl) {
          height: 900px !important;
        }
        .carousel {
          height: 100% !important;
          margin-bottom: 3rem;
          &-item {
            background: linear-gradient(rgb(2, 2, 2) 0%, rgba(2, 2, 2, 0) 100%);

            img {
              width: 120%;
            }
          }
          &-caption {
            &--top {
              position: absolute !important;
              top: 0 !important;
              right: 0 !important;
              left: 9%;
              text-align: unset !important;
              // bottom: 20px;
              z-index: 10;
              padding-top: 20px;
              padding-bottom: 20px;
              width: 200px;
              @include breakpoint($md) {
                left: 15%;
              }
              h4 {
                color: #fff;
                font-size: 3rem;
                @include breakpoint($md) {
                  font-size: 5rem;
                }
                @include breakpoint($lg) {
                  font-size: 8rem;
                }
              }
            }
            &--bottom {
              display: flex;
              align-items: flex-end;
              flex-direction: column;
              padding: 0 2rem;
              padding-bottom: 1rem;
              position: absolute !important;
              bottom: 5% !important;
              right: 3% !important;

              @include breakpoint($md) {
                flex-direction: row;
              }
              & > div {
                &:first-child {
                  width: 300px;
                }
              }
              p,
              ul,
              li {
                color: #fff;
                margin: 0;
              }
              li {
                text-transform: uppercase;
                font-family: $main-font;
              }
              p {
                color: #fff;
                text-transform: uppercase;
                font-family: $main-font;
                font-size: 1.2rem;
                @include breakpoint($md) {
                  font-size: 2rem;
                }
              }
            }
          }
        }
      }
      &__item {
        &--1,
        &--2,
        &--3,
        &--4 {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          height: 800px;
          padding: 8rem 2rem;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          @include breakpoint($md) {
            padding: 10rem 14rem;
          }
          @include breakpoint($lg) {
            padding: 10rem 18rem;
            height: 900px;
          }
          h2 {
            // color: #fff;
            font-size: 3rem;
            color: transparent;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #fff;
            @include breakpoint($md) {
              font-size: 4rem !important;
              width: 67%;
              margin-top: 3rem;
            }
            @include breakpoint($lg) {
              font-size: 6rem !important;
              width: 100%;
            }
          }
        }
        &--1 {
          background-image: url("../../assets/aplicaciones-acero-01.jpg") !important;
        }
        &--2 {
          background-image: url("../../assets/refineries.jpg") !important;
        }
        &--3 {
          // background-image: url("../../assets/acero-inoxidable-adornos-arquitectonicos.jpg") !important;
        }
        &--4 {
          background-image: url("../../assets/scientist.jpg") !important;
        }
      }
    }
    &-item {
      h2,
      h3,
      h4,
      h5,
      p {
        color: #fff;
      }
      h2 {
        font-size: 3rem;
        @include breakpoint($md) {
          font-size: 7rem;
        }
      }
      h3 {
        font-size: 2.5rem;
        @include breakpoint($md) {
          color: transparent;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: #fff;
          font-size: 4.5rem;
        }
      }
      h4 {
        font-size: 2rem;
        @include breakpoint($md) {
          color: transparent;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: #fff;
          font-size: 3.5rem;
        }
      }
      h5 {
        font-size: 2rem;
        @include breakpoint($md) {
          font-size: 3.5rem;
        }
      }
      &--1,
      &--2 {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      &--1 {
        background-image: url("../../assets/img-acero-b-1.jpg");
      }
      &--2 {
        background-image: url("../../assets/img-acero-b-1.jpg");
        .carousel--steel-item__heading {
          width: 100%;
          & > div {
            width: 65%;
            margin: 0 auto;
          }
        }
      }
      &__heading {
        text-align: center;
        & > div {
          display: flex;
          justify-content: space-between;
        }
      }
      &__properties {
        text-align: center;
        margin-top: 3rem;
        &__card {
          margin-top: 2rem;
          p {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
.steel {
  &-lineup {
    padding: 4rem 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.73),
        rgba(0, 0, 0, 0.83)
      ),
      url("../../assets/steel-2.jpg");
    @include breakpoint($md) {
      padding: 5rem 0;
    }
    .container-fluid {
      @include breakpoint($md) {
        padding: 0 3rem;
      }
      .w-sm {
        width: 70%;
      }
      h3 {
        text-align: center;
        color: #fff !important;
        @include breakpoint($md) {
          font-size: 6.25rem;
          margin-bottom: 3rem;
        }
      }
      img {
        @include breakpoint($xs) {
          width: auto !important;
        }
      }
    }
    h5 {
      color: #fff;
      text-align: center;
      font-size: 1.7rem;
      display: block;
      margin: 0.5rem auto 0 auto;
      height: 90px;
      @include breakpoint($md) {
        font-size: 2.5rem;
        min-height: 96px;
        height: auto;
      }
    }
    .product-info {
      border: 1px solid rgba(255, 255, 255, 1);
      padding: 20px;
      line-height: 120%;
      font-size: 15px;
      background-color: rgba(255, 255, 255, 0.25);
      background-image: none;
      margin-top: 20px;
      height: 425px;
      @include breakpoint($md) {
        height: 230px;
      }
      ul {
        padding-left: 0.8rem;
        list-style: disc;
        font-family: $secondary-font;
        font-weight: lighter;
        li {
          color: #fff;
          &:not(:first-child) {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}
