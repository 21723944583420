@import "../../scss/variables";
@import "../../scss/mixins";

.jumbotron {
  &--hero {
    &__card {
      display: flex;
      align-items: center;
      margin-top: 1rem;
      img,
      p {
        margin: 0;
      }
      p {
        color: #fff;
        font-size: 1.3rem;
      }
      img {
        height: 50px;
      }
    }
    &-pipeline {
      background-image: url("../../assets/tuberia-jumbotron.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 800px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @include breakpoint($md) {
        height: 104vh;
        margin-top: -8rem !important;
      }
      h1 {
        font-size: 4.5rem;
        text-align: center;
        color: #fff;
        text-shadow: 5px 5px 4px rgba(0, 0, 0, 0.5);
        @include breakpoint($lg) {
          font-size: 6rem;
        }
      }
      img.jupiter {
        width: 220px;
      }
      &__characteristics {
        background-image: url("../../assets/tuberia-caracteristicas-jumbotron.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: auto;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 0 !important;
        @include breakpoint($md) {
          height: 100vh;
          margin-bottom: 0 !important;
        }
        h3,
        p {
          color: #fff;
        }
        h3 {
          font-size: 2rem;
          @include breakpoint($md) {
            font-size: 6rem;
          }
        }
      }
    }
  }
}
.certifications {
  @include breakpoint($xs) {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  @include breakpoint($md) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  h2 {
    text-align: center;
    color: rgb(77, 77, 77);
    @include breakpoint($md) {
      font-size: 6.7rem;
      width: 70%;
      margin: 0 auto;
    }
    span {
      @include breakpoint($md) {
        font-size: 7.5rem;
      }
    }
  }
  &__logos {
    margin-top: 3rem;
    img {
      @include breakpoint($xs) {
        display: block;
        margin: 0 auto;
        margin-bottom: 1rem;
        max-height: 70px;
      }
    }
    .row {
      justify-content: space-between;
      align-items: center;
    }
  }
}
.carousel {
  height: 1200px;
  @include breakpoint($md) {
    // height: 100vh !important;
  }
  // @include breakpoint($lg) {
  //   height: 600px;
  // }
  // @include breakpoint($xl) {
  //   height: 800px;
  // }
  &--pipeline {
    &__applications {
      &-container {
        // height: 600px;
        position: relative;
        @include breakpoint($md) {
          // margin-bottom: 4rem;
          height: 800px;
        }
        @include breakpoint($xl) {
          height: 900px;
        }
        .carousel {
          height: 600px;
          @include breakpoint($md) {
            height: 100%;
          }
          &-item {
            img {
              width: 120%;
            }
          }
          &-caption {
            &--top {
              position: absolute !important;
              top: 0 !important;
              right: 0 !important;
              left: 9%;
              text-align: unset !important;
              // bottom: 20px;
              z-index: 10;
              padding-top: 20px;
              padding-bottom: 20px;
              width: 200px;
              @include breakpoint($md) {
                left: 15%;
              }
              h3,
              h5 {
                color: #fff;
                font-size: 3rem;
                @include breakpoint($md) {
                  font-size: 5rem;
                }
                @include breakpoint($lg) {
                  font-size: 8rem;
                }
              }
            }
            &--bottom {
              // display: flex;
              // align-items: flex-end;
              // flex-direction: column;
              // padding: 0 2rem;
              // padding-bottom: 1rem;
              position: absolute !important;
              bottom: 5rem !important;
              right: 0 !important;
              z-index: 2;
              @include breakpoint($md) {
                flex-direction: row;
              }
              & > div {
                &:first-child {
                  width: 300px;
                  @include breakpoint($md) {
                    width: 350px;
                  }
                  @include breakpoint($lg) {
                    width: 800px;
                  }
                }
              }
              p,
              ul,
              li {
                color: #fff;
                margin: 0;
                text-transform: uppercase;
              }
              p {
                text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.92);
                font-weight: bolder;
              }
            }
          }
        }
      }
      &__item {
        &--1,
        &--2,
        &--3,
        &--4 {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          height: 800px;
          padding: 8rem 2rem;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          @include breakpoint($md) {
            padding: 10rem 14rem;
            height: 800px;
          }
          @include breakpoint($lg) {
            padding: 10rem 18rem;
            height: 900px;
          }
          h2 {
            font-size: 3rem;
            color: transparent;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #fff;
            @include breakpoint($md) {
              width: 90%;
            }
            @include breakpoint($lg) {
              font-size: 6rem !important;
              width: 100%;
            }
            span {
              @include breakpoint($md) {
                font-size: 5rem;
              }
              @include breakpoint($lg) {
                font-size: 9rem;
              }
            }
          }
        }
        &--1 {
          background-image: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0.73),
              rgba(0, 0, 0, 0.01)
            ),
            url("../../assets/tuberia.jpg");
        }
        &--2 {
          background-image: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0.73),
              rgba(0, 0, 0, 0.01)
            ),
            url("../../assets/img-tuberia.jpg");
        }
        &--3 {
          background-image: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0.73),
              rgba(0, 0, 0, 0.01)
            ),
            url("../../assets/img-tuberia-04.jpg");
        }
        &--4 {
          background-image: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0.73),
              rgba(0, 0, 0, 0.01)
            ),
            url("../../assets/img-tuberia-03.jpg");
        }
      }
    }
    &-item {
      h2,
      h3,
      h4,
      p {
        color: #fff;
      }
      h2 {
        font-size: 4rem;
        @include breakpoint($md) {
          font-size: 5rem;
        }
      }
      h3 {
        font-size: 2.5rem;
        @include breakpoint($md) {
          font-size: 4rem;
        }
      }
      h4 {
        font-size: 2rem;
        @include breakpoint($md) {
          font-size: 3rem;
        }
      }
      &--1,
      &--2 {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      &--1 {
        background-image: url("../../assets/img-acero-b-1.jpg");
      }
      &--2 {
        background-image: url("../../assets/img-acero-b-1.jpg");
        .carousel--steel-item__heading {
          width: 100%;
          & > div {
            width: 65%;
            margin: 0 auto;
          }
        }
      }
      &__heading {
        text-align: center;
        & > div {
          display: flex;
          justify-content: space-between;
        }
      }
      &__properties {
        text-align: center;
        margin-top: 3rem;
        &__card {
          margin-top: 2rem;
          @include breakpoint($xs) {
            text-align: center;
          }
          p {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
.steel {
  &-lineup {
    padding: 4rem 0;
    @include breakpoint($md) {
      padding: 5rem 0;
    }
    h4 {
      text-align: center;
      color: #fff;
      font-size: 2.5rem;
      margin-bottom: 2rem;
      @include breakpoint($md) {
        font-size: 6.25rem;
        margin-bottom: 0;
      }
    }
    img.third {
      @include breakpoint($md) {
        height: 240px;
      }
    }
  }
}
.pipeline-lineup {
  img {
    @include breakpoint($xs) {
      height: 180px;
      width: 175px !important;
    }
    // width: 100%;
    height: 270px;
    margin: 0 auto;
    display: block;
    // width: 175px;
  }
}
