@import "../../scss/variables";
@import "../../scss/mixins";

.why-us {
  background-image: url("../../assets/why-us-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  padding: 5rem 0;
  @include breakpoint($md) {
    min-height: 100vh;
    margin-top: -101px;
  }
  h5 {
    color: #fff;
    font-size: 2.5rem;
    font-weight: lighter;
    text-align: center;
    @include breakpoint($md) {
      font-size: 4.5rem;
      width: 80%;
      margin: 4rem auto 0 auto;
    }
    @include breakpoint($lg) {
      font-size: 5rem;
    }
  }
  &__individual {
    display: flex;
    align-items: center;
    margin: 1.5rem 0;
    justify-content: center;
    @include breakpoint($md) {
      margin-bottom: 3.5rem;
    }
    h2,
    p {
      color: #fff;
    }
    h2 {
      font-size: 4.5rem;
    }
    p {
      font-size: 1.2rem;
      margin-left: 1rem;
      font-weight: lighter;
      width: 80%;
      @include breakpoint($md) {
        margin-bottom: 0;
        font-size: 1.3rem;
        width: 60%;
      }
    }
    & > div {
      width: 120px;
      text-align: center;
    }
    img {
      height: 60px;
      width: auto;
      @include breakpoint($md) {
        height: 75px;
        width: auto;
      }
    }
  }
}

.other-services {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.73),
      rgba(0, 0, 0, 0.33)
    ),
    url("../../assets/value-added-services-2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 650px;
  padding: 5rem 0;
  @include breakpoint($md) {
    height: auto;
    padding: 6rem 0;
  }
  h5 {
    color: #fff;
    font-size: 2.5rem;
    font-weight: lighter;
    text-align: center;
    @include breakpoint($md) {
      font-size: 4.5rem;
      width: 80%;
      margin: 0 auto;
    }
    @include breakpoint($lg) {
      font-size: 5rem;
    }
  }
  p {
    color: #fff;
    margin-top: 1rem;
    @include breakpoint($lg) {
      margin-top: 1.5rem;
      font-size: 1.5rem;
    }
  }
  .container {
    .row {
      text-align: center;
      margin-top: 2.5rem;
      @include breakpoint($md) {
      }
      img {
        height: 70px;
        @include breakpoint($md) {
          height: 100px;
        }
      }
      .last {
        margin-top: 5rem;
        @include breakpoint($md) {
          margin-top: 8rem;
        }
      }
    }
  }
}

.quality-assurance {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.73),
      rgba(0, 0, 0, 0.33)
    ),
    url("../../assets/value-added-services-4.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 1050px;
  padding: 5rem 0;
  @include breakpoint($md) {
    height: 100vh;
  }
  @include breakpoint($lg) {
    min-height: 100vh;
  }
  h5 {
    color: #fff;
    font-size: 2.5rem;
    font-weight: lighter;
    text-align: center;
    @include breakpoint($md) {
      font-size: 4.5rem;
      width: 80%;
      margin: 1rem auto 0 auto;
    }
    @include breakpoint($lg) {
      font-size: 5rem;
    }
  }
  p {
    color: #fff;
    margin-top: 1rem;
    font-weight: lighter;
    text-align: center;
    @include breakpoint($lg) {
      margin-top: 1.5rem;
      font-size: 1.3rem;
    }
    &:nth-child(2n + 1) {
      @include breakpoint($lg) {
        margin-bottom: 4rem;
      }
    }
  }
  .container {
    .row {
      text-align: center;
      margin-top: 2rem;
      @include breakpoint($md) {
        margin-top: 2.5rem;
      }
      img {
        height: 150px;
      }
    }
  }
}

.centro-de-servicio {
  padding: 2rem 0;
  @include breakpoint($md) {
    min-height: 100vh;
    padding: 5rem 0;
  }
  .nav.nav-pills {
    font-family: $main-font;
    font-size: 1.2rem;
  }
  .nav-link {
    color: #000;
  }
  .nav.nav-pills .nav-link.active {
    background-color: $main-blue;
    border-radius: 0;
  }
  .tab-pane {
    @include breakpoint($xs) {
      margin: 1rem 0;
      padding: 0 1rem;
    }
    ul {
      position: relative;
      padding-left: 0;
      margin-top: 1rem;
      @include breakpoint($md) {
        columns: 2;
      }
      &.third {
        @include breakpoint($md) {
          columns: 3;
        }
      }
      li {
        list-style: none;
        &::before {
          list-style: none;
          content: "";
          display: inline-block;
          height: 1rem;
          width: 1rem !important;
          background-image: url(../../assets/bullet--blue.svg);
          background-size: contain;
          background-repeat: no-repeat;
          margin-right: 0.5em;
        }
        &:not(:first-of-type) {
          margin-top: 0.6rem;
        }
        p {
          padding-left: 1.5rem;
          &:first-of-type {
            margin-top: 0.5rem;
          }
        }
        span {
          font-family: $main-font;
          font-size: 1.1rem;
        }
      }
    }
  }
  .video {
    // @include breakpoint($xs) {
    //   width: 0;
    //   height: 0;
    // }
    // @include breakpoint($xs) {
    //   height: 400px;
    // }
    // @include breakpoint($lg) {
    //   max-height: unset;
    // }
  }
}
