@import "../../scss/variables";
@import "../../scss/mixins";

.jumbotron {
  &--hero {
    &__card {
      display: flex;
      align-items: center;

      margin-top: 1rem;
      img,
      p {
        margin: 0;
      }
      p {
        color: #fff;
        font-size: 1.3rem;
      }
      img {
        height: 50px;
      }
    }
    &-aluminum {
      background-image: url("../../assets/aluminum-hero-bg.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 800px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 0 !important;
      @include breakpoint($md) {
        background-image: url("../../assets/aluminum-hero-bg.png");
        height: 100vh;
        margin-top: -8rem !important;
      }
      .video {
        @include breakpoint($xs) {
          width: 0;
          height: 0;
        }
        height: 100%;
        width: 200%;
        object-fit: cover;
      }
      h1 {
        font-size: 4.5rem;
        text-align: center;
        color: #fff;
        // font-size: 2rem;
        @include breakpoint($md) {
          font-size: 6rem;
        }
        @include breakpoint($lg) {
          font-size: 8rem;
        }
      }
      p {
        @include breakpoint($md) {
          font-size: 1.5rem;
        }
      }
      .container {
        position: absolute;
        width: 100%;
        top: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &__characteristics {
        background-image: url("../../assets/tuberia-caracteristicas-jumbotron.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 800px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        @include breakpoint($md) {
          height: 90vh;
          text-align: center;
        }
        h1,
        p {
          color: #fff;
        }
      }
    }
  }
}
.certifications {
  padding-top: 5rem;
  padding-bottom: 5rem;
  h3 {
    text-align: center;
    color: rgb(77, 77, 77);
    @include breakpoint($md) {
      font-size: 6.7rem;
      width: 70%;
      margin: 0 auto;
    }
    span {
      @include breakpoint($md) {
        font-size: 7.5rem;
      }
    }
  }
  &__logos {
    margin-top: 3rem;
    .row {
      justify-content: space-between;
      align-items: center;
    }
  }
}
.carousel {
  height: 850px;
  @include breakpoint($ipad-port) {
    height: 1000px;
  }
  @include breakpoint($md) {
    height: 650px;
  }
  @include breakpoint($lg) {
    height: 101vh !important;
  }
  @include breakpoint($xl) {
    height: 900px !important;
  }
  &--aluminum {
    &__applications {
      &-container {
        height: 600px;
        position: relative;
        @include breakpoint($md) {
          // margin-bottom: 4rem;
          height: 101vh;
        }
        @include breakpoint($xl) {
          height: 900px !important;
        }
        .carousel {
          height: 100%;
          &-item {
            img {
              width: 120%;
            }
          }
          &-caption {
            &--top {
              position: absolute !important;
              top: 0 !important;
              right: 0 !important;
              left: 9%;
              text-align: unset !important;
              // bottom: 20px;
              z-index: 10;
              padding-top: 20px;
              padding-bottom: 20px;
              width: 200px;
              @include breakpoint($md) {
                left: 15%;
              }
              h4 {
                color: #fff;
                font-size: 3rem;
                @include breakpoint($md) {
                  font-size: 5rem;
                }
                @include breakpoint($lg) {
                  font-size: 8rem;
                }
              }
            }
            &--bottom {
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              padding: 0 2rem;
              padding-bottom: 1rem;
              position: absolute !important;
              bottom: 5% !important;
              right: 3% !important;
              @include breakpoint($md) {
                flex-direction: column;
              }
              & > div {
                &:first-child {
                  // width: 300px;
                  // @include breakpoint($md) {
                  //   width: 600px;
                  // }
                  // @include breakpoint($lg) {
                  //   width: 800px;
                  // }
                }
              }
              p,
              ul,
              li,
              h4 {
                color: #fff;
                margin: 0;
                padding-left: 0;
              }
              li {
                text-transform: uppercase;
                font-family: $main-font;
                @include breakpoint($md) {
                  font-size: 1.8rem;
                }
              }
            }
          }
        }
      }
      &__item {
        &--1,
        &--2,
        &--3,
        &--4,
        &--5 {
          background-position: top;
          background-repeat: no-repeat;
          background-size: cover;
          height: 800px;
          padding: 8rem 2rem;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          @include breakpoint($md) {
            padding: 10rem 14rem;
          }
          @include breakpoint($lg) {
            padding: 10rem 18rem;
            height: 900px;
          }
          h2 {
            font-size: 2.5rem;
            color: transparent;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #fff;
            @include breakpoint($md) {
              font-size: 4rem !important;
              width: 67%;
            }
            @include breakpoint($lg) {
              font-size: 9rem !important;
              width: 100%;
            }
          }
        }
        &--1 {
          background-image: url("../../assets/img-aluminio-03.jpg") !important;
        }
        &--2 {
          background-image: url("../../assets/soldaduras.jpg") !important;
        }
        &--3 {
          background-image: url("../../assets/img-aluminio-06.jpg") !important;
        }
        &--4 {
          background-image: url("../../assets/plane-prominox.jpg") !important;
        }
        &--5 {
          background-image: url("../../assets/Minisplit.jpg") !important;
        }
      }
    }
    &-item {
      h2,
      h3,
      h4,
      h5,
      p {
        color: #fff;
      }
      h2 {
        // font-size: 4rem;
        @include breakpoint($md) {
          font-size: 5rem;
        }
      }
      h3 {
        font-size: 2.5rem;
        @include breakpoint($md) {
          color: transparent;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: #fff;
          font-size: 4.5rem;
        }
      }
      h4 {
        font-size: 2rem;
        @include breakpoint($md) {
          font-size: 3rem;
        }
      }
      h5 {
        font-size: 2rem;
        @include breakpoint($md) {
          font-size: 3.5rem;
        }
      }
      &--1,
      &--2,
      &--3,
      &--4,
      &--5,
      &--6,
      &--7,
      &--8 {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      &--1 {
        background-image: url("../../assets/aluminio-series-slide-1.jpg");
      }
      &--2 {
        background-image: url("../../assets/aluminio-series-slide-2.jpg");
        .carousel--steel-item__heading {
          width: 100%;
          & > div {
            width: 65%;
            margin: 0 auto;
          }
        }
      }
      &--3 {
        background-image: url("../../assets/img-aluminio-04.jpg");
      }
      &--4 {
        background-image: url("../../assets/img-aluminio-05.jpg");
      }
      &--5 {
        background-image: url("../../assets/img-aluminio-06.jpg");
      }
      &--6 {
        background-image: url("../../assets/img-aluminio-07.jpg");
      }
      &--7 {
        background-image: url("../../assets/img-aluminio-08.jpg");
      }
      &--8 {
        background-image: url("../../assets/img-aluminio-09.jpg");
      }
      &__heading {
        text-align: center;
        & > div {
          display: flex;
          justify-content: space-between;
        }
      }
      &__properties {
        text-align: center;
        margin-top: 3rem;
        @include breakpoint($md) {
          width: 900px;
        }
        &__card {
          margin-top: 2rem;
          p {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
.steel {
  &-lineup {
    padding: 4rem 0;
    @include breakpoint($md) {
      padding: 5rem 0;
    }
    h3 {
      text-align: center;
      color: #fff;
      font-size: 2.4rem;
      margin-bottom: 1.5rem;
      @include breakpoint($md) {
        font-size: 6.25rem;
        margin-bottom: 0;
      }
    }
    img {
      &:first-of-type {
        width: 350px;
      }
      @include breakpoint($xs) {
        width: 175px;
      }
    }
    // .col-6 {
    //   padding-right: 0;
    // }
  }
}
.aluminum-grades {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  // height: 100vh;
}
.grade {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 250px;
  @include breakpoint($lg) {
    height: 800px;
  }
  &-1000 {
    background-image: url("../../assets/20.png");
  }
  &-3000 {
    background-image: url("../../assets/21.png");
  }
  &-5000 {
    background-image: url("../../assets/22.png");
  }
  &-6000 {
    background-image: url("../../assets/23.png");
  }
  &-8000 {
    background-image: url("../../assets/24.png");
    // height: 300px;
    @include breakpoint($lg) {
      height: 900px;
    }
  }
}
