@import '../../scss/variables';
@import '../../scss/mixins';

.category {
    background-color: #cdcecf;
    height: 100%;
    overflow-x: hidden;
    padding: 0;
    @include breakpoint($md) {
        padding: 0.75rem 0 0;
    }
    &-container {
        position: relative;
        .card-columns {
            @include breakpoint($lg) {
                column-count: 2;
                .card {
                    &:nth-child(odd) {
                        padding-left: 1rem;
                    }
                }
            }
        }
        .container-fluid {
            padding: 0;
            h1 {
                z-index: 9;
                position: relative;
            }
            .row {
                .col-lg-6 {
                    margin-top: 1rem;
                    padding-left: 0;
                    &:nth-child(odd) {
                        height: 430px;
                        .article-card {
                            &-media-top {
                                @include breakpoint($lg) {
                                    height: auto;
                                    overflow-y: hidden;
                                }
                            }
                        }
                    }
                    &:nth-child(even) {
                        margin-top: 1rem;
                        height: 470px;
                        .article-card {
                            &-media-top {
                                img {
                                    @include breakpoint($lg) {
                                        height: 100%;
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                    &:nth-child(2) {
                        margin-top: 0;
                    }
                    &:nth-child(3) {
                        @include breakpoint($lg) {
                            margin-top: -1.5rem;
                            height: 750px;
                            .article-card {
                                &-media-top {
                                    @include breakpoint($lg) {
                                        height: auto;
                                        overflow-y: hidden;
                                        img {
                                            height: 100%;
                                            width: auto;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:nth-child(4) {
                        @include breakpoint($lg) {
                            margin-top: 1rem;
                            height: 380px;
                            .article-card {
                                &-media-top {
                                    @include breakpoint($lg) {
                                        height: auto;
                                        overflow-y: hidden;
                                        img {
                                            width: 100%;
                                            height: auto;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:nth-child(6) {
                        @include breakpoint($lg) {
                            margin-top: -20rem;
                            height: 320px;
                            .article-card {
                                &-media-top {
                                    img {
                                        height: auto;
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
