@import "../../scss/variables";
@import "../../scss/mixins";

.hero--legal {
  height: 120px;
  @include breakpoint($md) {
    height: 170px;
  }
  .jumbotron {
    &--legal {
      background-image: url("../../assets/legal-hero-bg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      height: 100%;
      @include breakpoint($md) {
        margin-top: -101px;
      }
    }
  }
}
.legal {
  &__info {
    padding: 3rem 0;
    @include breakpoint($md) {
      padding: 6rem 0;
    }
    h1 {
      font-size: 4rem;
      @include breakpoint($md) {
        font-size: 6rem;
        margin-bottom: 3rem;
      }
      &.sm {
        font-size: 2.5rem;
        @include breakpoint($md) {
          font-size: 4rem;
        }
      }
    }
    p,
    ol {
      text-align: justify;
      line-height: 2;
      font-family: $secondary-font;
      span {
      }
    }
  }
}
