@import "../../scss/variables";
@import "../../scss/mixins";

.contact-us {
  &-jumbotron {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.73),
        rgba(0, 0, 0, 0.33)
      ),
      url("../../assets/contact-jumbotron.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px;
    @include breakpoint($md) {
      margin-top: -101px;
      height: 100vh;
    }
    .container {
      height: 100%;
      .row {
        height: 100%;
        .col-12,
        .col-lg-6 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          @include breakpoint($md) {
            justify-content: center;
            padding-bottom: 5rem;
          }
          h2 {
            color: #fff;
            font-size: 3rem;
            font-family: $secondary-font;
            margin-bottom: 2rem;
          }
          ul {
            padding-left: 0;
            list-style: none;
            li {
              &:not(:first-child) {
                margin-top: 1.5rem;
              }
              img {
                width: 30px;
                margin-right: 1rem;
                @include breakpoint($md) {
                  width: 37px;
                }
                &.second {
                  width: 37px;
                  @include breakpoint($md) {
                    width: 43px;
                  }
                }
              }
              a {
                color: #fff;
                font-family: $secondary-font;
                font-size: 1.3rem;
                font-weight: lighter;
              }
            }
          }
        }
      }
    }
  }
}
