@import "../../scss/variables";
@import "../../scss/mixins";
.navbar {
  // background-color: transparent !important;
  // position: absolute !important;
  // top: 0;
  z-index: 999;
  @include breakpoint($xs) {
    background-color: #fff;
  }
  @include breakpoint($md) {
    height: 101px;
  }

  &-brand {
    a {
      img {
        height: 80px;
        @include breakpoint($md-down) {
          height: 70px;
        }
      }
    }
  }
  &-nav {
    display: flex;
    @include breakpoint($ipad-land) {
      align-items: center;
    }
    a {
      font-family: $secondary-font;
      font-size: 16px;
      color: #000;
      letter-spacing: 0.2px;
      font-weight: 500;
      text-transform: uppercase;
      margin-top: 0.7rem;
      @include breakpoint($ipad-port) {
        margin-top: 0;
      }
      @include breakpoint($ipad-land) {
        color: #fff;
      }
      &:not(:last-child) {
        margin-right: 1.2rem;
        @include breakpoint($lg) {
          margin-right: 1.5rem;
        }
      }
      @include breakpoint($md) {
        &:hover {
          color: #fff;
          text-decoration: none;
        }
      }
    }
    .dropdown-item.active,
    .dropdown-item:active {
      background-color: transparent;
    }
    .dropdown {
      margin-right: 1.2rem;
      z-index: 9999;
      a {
        color: #000;
        margin: 0;
        margin-top: 0.5rem;
        @include breakpoint($ipad-port) {
          color: #fff;
          margin-top: 0;
        }
        @include breakpoint($md-down) {
          padding: 0;
        }
        &:first-child {
          color: #000;
          @include breakpoint($ipad-land) {
            &:hover {
              color: #fff;
              text-decoration: none;
            }
            color: #fff;
          }
        }
      }
      &-item {
        .active {
          background-color: none !important;
        }
      }
      &-menu {
        width: 280px;
        padding: 0;
        border: 0;
        // background-color: rgba(255, 255, 255, 0.178);
        @include breakpoint($ipad-port) {
          background-color: #003495a4;
          // border: 1px solid rgba(0, 0, 0, 0.15);
          border: none;
          border-bottom: 2px solid #fff;
          border-radius: 0;
        }
        a {
          text-transform: capitalize !important;
          // padding: 0.9rem 0.5rem;
          // background-color: #fff !important;
          margin-top: 0.4rem;
          color: #fff;
          padding-top: 10px;
          padding-bottom: 10px;
          @include breakpoint($md-down) {
            color: #000;
          }
          @include breakpoint($md) {
            &:hover {
              text-decoration: none;
              background-color: $main-blue;
            }
          }
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  @include breakpoint($ipad-land) {
    color: #fff !important;
  }
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  @include breakpoint($ipad-land) {
    color: #fff !important;
  }
}
