@import "../../scss/variables";
@import "../../scss/mixins";

.footer {
  background-color: $main-blue;
  padding: 4rem 0;
  @include breakpoint($md) {
    padding: 3rem 0;
  }
  &__l {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include breakpoint($md) {
      align-items: flex-start;
    }
    a {
      color: #fff;
      text-transform: uppercase;
      font-size: 1.1rem;
      @include breakpoint($md) {
        font-size: 1rem;
      }
      // &:last-child {
      //   margin-top: 0.5rem;
      //   @include breakpoint($md) {
      //     margin-top: 0.8rem;
      //   }
      // }
      &:hover {
        color: #fff;
      }
    }
  }
  &__m {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
    @include breakpoint($md) {
      margin-top: 0;
    }
    p,
    a {
      text-transform: uppercase;
      color: #fff;
      font-size: 1rem;
      @include breakpoint($md) {
        font-size: 1rem;
      }
    }
    a {
      &:hover {
        color: #fff;
        text-decoration: none;
      }
    }
    &__social {
      margin-top: 1rem;
      a {
        // &:nth-child(even) {
        //   margin: 1rem 0;
        // }
        img {
          height: 55px;
        }
      }
    }
  }
  &__r {
    display: flex;
    justify-content: center;

    margin-top: 2rem;
    @include breakpoint($md) {
      margin-top: 0;
    }
    // @include breakpoint($lg) {
    //   margin-top: 2rem;
    // }
  }
}
